import { SelectableValue } from '@grafana/data';
import { AWSDataSourceJsonData, AWSDataSourceSettings, AWSDataSourceType, CreateDataSourceParams } from 'types/types';
import { buildARN, createDataSource, serviceToDSMap, shouldSetAssumeRole } from './datasource';

export enum ESFlavor {
  Elasticsearch = 'elasticsearch',
  OpenSearch = 'opensearch',
}

export interface ESVersion {
  flavor: ESFlavor;
  version: string;
}

export interface ElasticsearchJsonData extends AWSDataSourceJsonData {
  database: string;
  timeField?: string;
  version?: string;
  flavor?: ESFlavor;
  esVersion?: ESVersion['version'];
  interval?: string;
  timeInterval?: string;
  maxConcurrentShardRequests?: number;
  logMessageField?: string;
  logLevelField?: string;
  dataLinks?: DataLinkConfig[];
  sigV4Auth: boolean;
  sigV4AuthType: string;
  sigV4AssumeRoleArn?: string;
  sigV4Region: string;
  pplEnabled: boolean;
  serverless: boolean;
}

export type DataLinkConfig = {
  field?: string;
  url?: string;
  datasourceUid?: string;
};

export type OpenSearchDataSourceSettings = AWSDataSourceSettings<ElasticsearchJsonData>;

export interface CreateOpenSearchDataSourceParams extends CreateDataSourceParams {
  id: string;
  endpoint: string;
  serverless?: boolean;
  database?: string;
  version?: ESVersion;
}

export function createOpenSearchDataSource(
  params: CreateOpenSearchDataSourceParams,
  installedDSNames: Set<string>,
  workspaceAccountId: string
) {
  let dsSettings: OpenSearchDataSourceSettings = {
    url: params.endpoint,
    jsonData: {
      database: params.database || '',
      timeField: '@timestamp',
      sigV4Auth: true,
      sigV4AuthType: 'ec2_iam_role',
      sigV4Region: params.region,
      pplEnabled: !params.serverless,
      serverless: params.serverless ?? false,
      ...(params.version || !params.serverless
        ? params.version || { flavor: ESFlavor.OpenSearch, version: '1.0.0' }
        : {}),
    },
  };

  if (params.account && params.roleName && dsSettings.jsonData && shouldSetAssumeRole(params, workspaceAccountId)) {
    dsSettings.jsonData.sigV4AssumeRoleArn = buildARN(params.account.id, params.roleName);
  }

  const dsName = `${serviceToDSMap.opensearch.name} ${params.id}`;
  return createDataSource(AWSDataSourceType.OpenSearch, dsName, dsSettings, installedDSNames);
}

export const AVAILABLE_FLAVORS: Array<SelectableValue<string>> = [
  { label: 'OpenSearch', value: ESFlavor.OpenSearch },
  { label: 'ElasticSearch', value: ESFlavor.Elasticsearch },
];

/**
 * @param rawVersion the raw `ElasticsearchVersion` string returned from https://docs.aws.amazon.com/cli/latest/reference/es/describe-elasticsearch-domains.html
 * can be a string in the form of `x.x` for Elasticsearch clusters or `OpenSearch_x.x` for OpenSearch.
 * @return a valid `ESVersion` object if rawVersion can be parsed to a valid Elasticsearch or OpenSearch version, otherwise return undefined.
 */
export const extractESVersion = (rawVersion = ''): ESVersion | undefined => {
  if (/^\d+\.\d+/.test(rawVersion)) {
    return {
      flavor: ESFlavor.Elasticsearch,
      version: rawVersion + '.0',
    };
  }

  const match = rawVersion.match(/^OpenSearch_(\d+\.\d+)/)?.[1];
  if (match) {
    return {
      flavor: ESFlavor.OpenSearch,
      version: match + '.0',
    };
  }

  return;
};
