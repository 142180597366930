import React, { FormEvent, HTMLProps } from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';
import { Checkbox } from '@grafana/ui';

type CheckboxProps = Partial<TableToggleAllRowsSelectedProps> & {
  disabled?: HTMLProps<HTMLInputElement>['disabled'];
};

export const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function IndeterminateCheckbox(
  { indeterminate, disabled, ...rest },
  outerRef
) {
  const innerRef = React.useRef<HTMLInputElement>(null);
  React.useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(outerRef, () => innerRef.current, []);
  React.useEffect(() => {
    if (innerRef.current) {
      innerRef.current.indeterminate = !!indeterminate;
    }
  }, [indeterminate]);

  const onChange = (e: FormEvent<HTMLInputElement>) =>
    rest.onChange ? rest.onChange({ ...e, target: e.currentTarget }) : undefined;

  return <Checkbox ref={innerRef} {...rest} onChange={onChange} disabled={disabled} />;
});
