import { useAsync, useAsyncFn } from 'react-use';
import { AWSDataSourceJsonData, AWSDataSourceSettings, ServiceInfo } from '../types/types';
import { getServiceDataSources } from 'provisioning/datasource';
import { getAccounts, getAllDataSources, getConfig, getRegions } from 'api';

const getDataSources = async (service: string): Promise<Array<AWSDataSourceSettings<AWSDataSourceJsonData>>> => {
  const allDataSources = await getAllDataSources();
  return getServiceDataSources(service, allDataSources);
};

export const useData = (service: ServiceInfo) => {
  const { value: accountsResponse, loading: accountsLoading } = useAsync(getAccounts);
  const accounts = accountsResponse?.accounts;

  const { value: regionsResponse, loading: regionsLoading } = useAsync(() => getRegions(service.id), [service.id]);
  const regions = regionsResponse?.regions;

  let { value: installedDataSources, loading: dataSourcesLoading } = useAsync(
    () => getDataSources(service.id),
    [service.id]
  );

  const [getDataSourcesState, fetchDataSources] = useAsyncFn(() => getDataSources(service.id), [service.id]);
  if (getDataSourcesState.value) {
    installedDataSources = getDataSourcesState.value;
  }

  const { value: appConfig, loading: configLoading } = useAsync(() => getConfig(), []);

  const dataLoading =
    accountsLoading || regionsLoading || dataSourcesLoading || getDataSourcesState.loading || configLoading;

  const warnings = [...(accountsResponse?.warnings || []), ...(regionsResponse?.warnings || [])];

  return { loading: dataLoading, warnings, regions, accounts, appConfig, installedDataSources, fetchDataSources };
};
