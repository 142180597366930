import { ServiceInfo, DataType, AWSServiceType } from '../types/types';

/**
 * Determine which is the main data type to display
 * @param service
 */
export const getDataType = (service: ServiceInfo): DataType => {
  if (service.id === AWSServiceType.OpenSearch) {
    return 'domain';
  } else if (service?.isFlat) {
    return 'account';
  }
  return 'workspace';
};
